import React from 'react';
import {Link} from 'react-router-dom';
import {Button} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    mainContainer: {
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'space-evenly',
        marginTop: '20px'
    },

    itemList: {
        alignItems: 'center',
        display: 'flex',
        listStyleType: 'none',
        margin: 0,
        padding: 0
    },

    itemSeparator: {
        marginBottom: '5px',
        marginLeft: '4px',
        marginRight: '4px'
    }
}));

const Header = (props) => {
    const { classes, cx } = useStyles();

    const headerItems = [
        {
            to: '/',
            text: 'Home'
        },
        ...(props.items || [])
    ]

    return (
        <header className={cx(classes.mainContainer)}>
            <ul className={cx(classes.itemList)}>
                {
                    headerItems.map((item, index) => {
                        return (
                            <React.Fragment key={index}>
                                <li>
                                    <Button
                                        component={Link}
                                        to={item.to}
                                        disableRipple={true}
                                    >
                                        {item.text}
                                    </Button>
                                </li>
                                { index !== headerItems.length - 1 ? <span className={cx(classes.itemSeparator)}>&#8226;</span> : "" }
                            </React.Fragment>
                        )
                    })
                }

            </ul>
        </header>
    )
}

export default Header;
