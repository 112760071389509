import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Box } from '@mui/material';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import Home from './pages/Home/Home';
import Portfolio from './pages/Portfolio/Portfolio';
import BigDay from './pages/BigDay/BigDay';
import BigDayPhotos from './pages/BigDayPhotos/BigDayPhotos';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import ScrollToTop from './components/ScrollToTop/ScrollToTop';
import Redirector from './components/Redirector/Redirector';
import './App.scss';

function App() {
    const blogHost = 'https://blog.cmtybur.com';
    const blogPaths = ['/2014/*', '/2013/*', '/2012/*', '/2011/*', '/2010/*', '/2009/*', '/index.php', '/index.php/*', '/projects', '/about',
        '/tag/*', '/category/*'];
    const newBlogURL = `${blogHost}${window.location.pathname}`;

    return (
        <Box>
            <BrowserRouter>
                <ScrollToTop>
                    <Routes>
                        {
                            blogPaths.map((path, index) => {
                                return <Route key={index} path={path} element={<Redirector newURL={newBlogURL} />} />
                            })
                        }
                        <Route path='/' element={<Home />} />
                        <Route path='/big-day' element={<BigDay />} />
                        <Route path='/big-day/photos' element={<BigDayPhotos />} />
                        <Route path='/portfolio' element={<Portfolio />} />
                        <Route path='*' element={<ErrorPage />} />
                    </Routes>
                </ScrollToTop>
            </BrowserRouter>
        </Box>
    );
}

export default App;
