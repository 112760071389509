import React, {useEffect} from 'react';
import {Link} from 'react-router-dom';
import {Box, Button} from '@mui/material';
import { makeStyles } from 'tss-react/mui';

const useStyles = makeStyles()(() => ({
    mainContainer: {
        alignItems: 'center',
        display: 'flex',
        alignContent: 'center',
        flexDirection: 'column',
        margin: '20px',

        '& div': {
            textAlign: 'center'
        },

        '& section': {
            marginTop: '25px',
            textAlign: 'center'
        }
    }
}));

const Home = () => {
    const { classes, cx } = useStyles();

    useEffect(() => {
        document.title = 'Chris Tybur - Home';
    });

    return (
        <Box className={cx(classes.mainContainer)}>
            <div>
                <h3>Home</h3>
            </div>

            <div>
                This is Chris Tybur's personal site. Enjoy your visit.
            </div>

            <section>
                <Button
                    href='https://blog.cmtybur.com'
                    disableRipple={true}
                    title='Go to the blog'
                >
                    Blog
                </Button>

                <div>
                    My sporadically-updated blog.
                </div>
            </section>

            <section>
                <Button
                    href='https://github.com/thisiscmt'
                    disableRipple={true}
                    title='Go to my GitHub page'
                >
                    GitHub
                </Button>

                <div>
                    Some personal projects I tinker with.
                </div>
            </section>

            <section>
                <Button
                    component={Link}
                    to='/big-day'
                    disableRipple={true}
                    title='Our big days'
                >
                    Big Days
                </Button>

                <div>
                    A chronicle of some big days in 2021 and 2022.
                </div>
            </section>
        </Box>
    )
}

export default Home;
